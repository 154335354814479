import { api } from '../plugins/axios'
import moment from 'moment'
export default {
  computed: {
    xsOnly () {
      return this.$vuetify.breakpoint.xsOnly
    },
    imgbaseurl () {
      return process.env.VUE_APP_IMAGE_BASEURL
    },
    // validations
    $_requiredValidation () {
      return [
        v => !!v || 'Required',
        v => !/^\s*$/.test(v) || 'Required'
      ]
    },
    $_emailValidation () {
      return [
        (v) => (v)
        ? /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/.test(v)
          || 'Invalid Email Address'
        : true
      ]
    },
  },
  methods: {
    $_execute (method, url, payload, config) { // api
      return api[method](url, payload, config)
    },
    $_formatDate (date, fromFormat, toFormat) { // date methods
      return date ? moment(date, fromFormat).format(toFormat) : null
    },
    $_isSameDate (start, end) {
      const from = this.$_formatDate(start, 'YYYY.MM.DDTHH:mm', 'YYYY.MM.DD')
      const to = this.$_formatDate(end, 'YYYY.MM.DDTHH:mm', 'YYYY.MM.DD')
      return start && end ? moment(moment(from, 'YYYY.MM.DD')).isSame(moment(to, 'YYYY.MM.DD')) : null
    },
    $_utcToLocal (date, fromFormat, toFormat) {
      if (!date) return null
      date = this.$_formatDate(date, fromFormat, 'DD.MM.YYYY HH:mm')
      return date ? toFormat || fromFormat ? moment(moment.utc(date, 'DD.MM.YYYYTHH:mm:ss').toDate()).format(toFormat || fromFormat) : moment.utc(date, 'DD.MM.YYYYTHH:mm:ss').toDate() : null
    },
    // general
    $_copy (data) {
      return data ? JSON.parse(JSON.stringify(data)) : null
    },
    $_scrollIntoView (selector) {
      document.querySelector(selector).scrollIntoView({ behavior: 'smooth' })
    }
  }
}