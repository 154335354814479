import axios from 'axios'
import eventBus from '../main'

const api = axios.create({
  baseURL: process.env.VUE_APP_URL
})

api.interceptors.response.use((res) => {
  // const { data } = res
  // if (res.status.toString().charAt(0) === '2' && res.data) res.data = $_recursiveUpdate(JSON.parse(JSON.stringify(data)), { utcToLocal: true })
  return res
}, (error) => {
  if (!error || !error.response) return Promise.reject(Error('Server error'))

  let { status, data, statusText } = error.response
  if (status >= 400) {
    switch (status) {
      // case 401:
      //   store.commit('logout')
      //   eventBus.$emit('snackbar', {
      //     message: ((data.err) ? data.err.message : (data || statusText)),
      //     color: 'error darken-1'
      //   })
      //   break
      default:
        eventBus.$emit('snackbar', {
          text: ((data.err) ? data.err.message : (data || statusText)),
          color: 'error darken-1'
        })
        break
    }
  }

  return Promise.reject(error)
  // if (response.data.err) {
  //   let { status, message } = response.data.err
  //   if (status === 401) { // handle unauthorized
  //     store.commit('logout')
  //   } else if (status >= 400) {
  //     if (status === 429) {
  //       console.log('Too many requests')
  //       eventBus.$emit('snackbar', { message: 'Too many requests, please try again later', color: 'error' })
  //     } else {
  //       eventBus.$emit('snackbar', { message, color: 'error' })
  //     }
  //   }
  // }
})

export { api }