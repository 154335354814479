<template>
  <v-app>
    <nav-bar></nav-bar>
    <layout></layout>
    <v-main>
      <router-view></router-view>
      <footer-view></footer-view>
    </v-main>
  </v-app>
</template>
<script>
export default {
  data () {
    return {}
  },
  mounted () {},
  components: {
    'nav-bar': () => import('@/components/Navbar.vue'),
    layout: () => import('@/components/Layout.vue'),
    'footer-view': () => import('@/components/Footer.vue')
  }
}
</script>
<style>
  html {
    -ms-overflow-style: none;
    scrollbar-width: none;
    user-select: none !important;
    scroll-behavior: smooth;
  }
  html::-webkit-scrollbar {
    display: none;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .v-list-item__action {
    margin-right: 17px !important;
  }
  .v-list-item {
    min-height: 42px !important;
  }
  .v-list-item__icon {
    margin: 8.5px 15px 0 0 !important;
  }
  .v-text-field__details {
    padding: 0 5px !important;
  }
  .v-messages__message {
    font-size: 11px !important;
  }
  .user-select-none {
    -webkit-user-select: none !important; /* Safari */
    -ms-user-select: none !important; /* IE 10 and IE 11 */
    user-select: none !important; /* Standard syntax */
  }
  .hover-red:hover {
    color: #ff5252 !important;
  }
</style>
