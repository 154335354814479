import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// the below code is used to avoid reduntant same location push errors
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [{
    path: '*',
    redirect: '/'
  }, {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue')
  }, {
    path: '/rooms',
    component: () => import(/* webpackChunkName: "rooms" */ '@/views/rooms/Index.vue'),
    children: [{
      path: '/',
      name: 'Rooms.List',
      component: () => import(/* webpackChunkName: "rooms" */ '@/views/rooms/List.vue')
    }]
  }, {
    path: '/enquiry',
    name: 'Enquiry',
    component: () => import(/* webpackChunkName: "enquiry" */ '@/views/others/Enquiry.vue')
  }]
})


router.afterEach(() => {
  window.scrollTo(0, 0)
})

export default router
