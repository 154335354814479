import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        success: '#008000',
        error: '#df323b',
        primary: '#00479b',
        darkenGrey: '#202020'
      }
    }
  }
})
